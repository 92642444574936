.loginCenter .Polaris-Page__Content {
  position: absolute;
  top: calc(100vh/2 - 267px/2); // 260px is the height of card
  left: calc(100vw/2 - 340px/2); // 340px is the width of card
}
.loginCenter .background {
  height: 100vh;
  position: relative;
}
.loginCenter .Polaris-TextField {
  width: 300px;
}
.loginCenter .helperText {
  position: absolute;
  top: -15px;
  left: 5px;
  padding: 3px 3px 0px 3px;
  z-index: 300;
  background: white;
  font-size: 10px;
  font-weight: 300;
}
.loginCenter .input-placeholder-right {
  position: relative;
  margin-top: 20px;

  /* webkit solution */
  ::-webkit-input-placeholder {
    text-align: right;
  }

  /* mozilla solution */
  input:-moz-placeholder {
    text-align: right;
  }
}
