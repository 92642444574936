// For Horse Variant show publication status UI

@import "login-page";

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}

.availableDot {
  background-color: #108043;
}

.unavailableDot {
  background-color: #D12D1C;
}

/**
  Horse Variant component START
*/

// Fix the truncation of badges
.horse-variant .product-caption .Polaris-Badge {
  display: block;
  max-width: min-content;
}

// Make sure the thumbnail isn't too big
.horse-variant {
  .Polaris-Thumbnail {
    width: 48px;
    height: 48px;
  }
}

@media (min-width: 1920px) {
  // Set a max width on desktop
  .Polaris-IndexTable__TableRow>.Polaris-IndexTable__TableCell:has(.horse-variant) {
    width: 440px;
  }

  .horse-variant .product-caption {
    width: 374px;
    padding-left: 0.75rem;
  }

  .Polaris-IndexTable__TableRow>.Polaris-IndexTable__TableCell:has(.medium_hv .horse-variant) {
    width: 380px;
  }

  .medium_hv .horse-variant .product-caption {
    width: 282px;
    padding-left: 0.75rem;
  }
}

@media (max-width: 1920px) {
  // Set a max width on desktop
  .Polaris-IndexTable__TableRow>.Polaris-IndexTable__TableCell:has(.horse-variant) {
    width: 300px;
  }

  .horse-variant .product-caption {
    width: 234px;
    padding-left: 0.75rem;
  }
}

@media (max-width: 768px) {
  // Set a max width on mobile
  .Polaris-IndexTable__TableRow>.Polaris-IndexTable__TableCell:has(.horse-variant) {
    max-width: 120px;
  }

  .horse-variant .product-caption {
    width: 102px;
    padding-left: 0;
  }

  // Hide product photos on mobile
  .Polaris-LegacyStack__Item:has(.product-photo) {
    display: none;
  }
}

/**
  Horse Variant component END
*/

/**
  Barcode scanning START
*/

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px #aef4af;
  }

  to {
    box-shadow: 0 0 10px 10px #aef4af;
  }
}

@-webkit-keyframes glow {
  from {
    box-shadow: 0 0 10px -10px #aef4af;
  }

  to {
    box-shadow: 0 0 10px 10px #aef4af;
  }
}

input.glow-animation {
  animation: glow 1s infinite alternate;
  -webkit-animation: glow 1s infinite alternate;
}

/**
  Barcode scanning END
*/

// Always center small modals, no matter the screen-width
.Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeSmall {
  margin: auto;
}

.w120 {
  width: 120px;
}

// This is fixing a Polaris bug
// A popover in a popover in a popover seems to break. So we manually give it a greater z
.Polaris-Popover__PopoverOverlay--open:has(#force-greater-z) {
  z-index: 600;
}

// Polaris bug: https://github.com/Shopify/polaris/issues/11248
.Polaris-IndexTable__ScrollBarContainer.Polaris-IndexTable--scrollBarContainerHidden {
  overflow: hidden;
}

.UndoMajor {
  width: 16px;
  height: 16px;
}

// Make highlighted rows more stylish
.Polaris-ResourceItem:hover {
  border-radius: 8px;
}

// Hide the skeleton image when it has no children
.Polaris-EmptyState__SkeletonImage.Polaris-EmptyState--loaded:empty {
  display: none;
}